<template>
    <div id="page-user-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                    <vs-button
                        @click="addPackage"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus">
                        {{$t('root.addPack')}}
                    </vs-button>
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery"
                          @input="updateSearchQuery" :placeholder="$t('button.search')"/>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="organizationPackageList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="20"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

import CellRendererActions from "./cell-renderer/CellRendererActions.vue"

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        CellRendererActions,
    },
    data() {
        return {
            profiles: [
                {value: 1, label: "360P"},
                {value: 2, label: "480P"},
                {value: 3, label: "640p"},
                {value: 8, label: "720p"},
                {value: 9, label: "1080p"},
                {value: 12, label: "HQ"}

            ],
            timeStorages: [
                {value: 1, label: '1 ' + `${this.$t('root.day')}`},
                {value: 3, label: '3 ' + `${this.$t('root.day')}`},
                {value: 7, label: '7 ' + `${this.$t('root.day')}`},
                {value: 15, label: '15 ' + `${this.$t('root.day')}`},
                {value: 30, label: '30 ' + `${this.$t('root.day')}`}
            ],
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 70
                },
                {
                    headerName: this.$t('root.packageName'),
                    field: 'packageName',
                    width: 150
                },
                {
                    headerName: this.$t('root.storageTime'),
                    field: 'timeStorage',
                    width: 100
                },
                {
                    headerName: this.$t('root.resolution'),
                    field: 'profile',
                    width: 100
                },
                {
                    headerName: this.$t('root.storedData'),
                    field: 's3_quota',
                    width: 100,
                },
                {
                    headerName: this.$t('root.usingAI'),
                    field: 'permissionFunctionss',
                    width: 350,
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'transactions',
                    width: 100,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            // Cell Renderer Components
            components: {
                CellRendererActions,
            },
            organizationPackageList: []
        }
    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.isUpdateOrgPackage'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('UPDATE_ORG_PACKAGE', false);
            }
        },
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        addPackage() {
            this.$router.push('/admin/organization-package/add').catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/camera-package/find-all').then((response) => {
                this.organizationPackageList = response.data;
                if (response.data.length > 0) {
                    response.data.forEach(c => {
                        c.permissionFunctionss = "";
                        c.permissionFunctions.forEach(permissionFunctionss => {
                            c.permissionFunctionss += permissionFunctionss.functionAlias + ", ";
                        });
                        c.permissionFunctionss = c.permissionFunctionss.slice(0, c.permissionFunctionss.length - 2);
                    })
                    response.data.forEach(relusion => {
                        let profiless = this.profiles.find(x => x.value === relusion.profile);
                        relusion.profile = profiless.label;
                    })
                    response.data.forEach(timeStora => {
                        let timeStoragess = this.timeStorages.find(x => x.value === timeStora.timeStorage);
                        timeStora.timeStorage = timeStoragess.label;
                    })

                }
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>

</style>
